import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentMethod from "./PaymentMethod";

const StripePublicKey = process.env;
// const StripePublicKey = process.env.REACT_APP_STRIP_KEY;
const stripePromise = loadStripe(StripePublicKey);

const StripeContainer = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentMethod />
    </Elements>
  );
};

export default StripeContainer;
