import React, { useEffect } from "react";
import Payment from "../../Components/ProfileTabs/Payment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AddNewCard from "../Contrato/AddNewCard";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

// Live
const zishuMalek2 = process.env;
// const zishuMalek2 = process.env.REACT_APP_STRIP_KEY;
const stripePromise = loadStripe(zishuMalek2);

const StripeContainer2 = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);
  return (
    <div>
      <Elements stripe={stripePromise}>
        <Payment />
      </Elements>
    </div>
  );
};

export default StripeContainer2;
