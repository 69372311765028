import { Modal } from "react-bootstrap";
import Button from "../../UI/Button";

export function Informationalert(props) {
  return (
    <div className="alertinfo">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        style={{ zIndex: "10000" }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="white text-center">
          <div className="d-flex flex-column gap-2">
            <h3>Información importante</h3>
            <p className="Modal-text">
              El inventario del Kia EV6 es limitado. Para conocer la
              disponibilidad actual, te invitamos a comunicarte con tu dealer
              Kia favorito.
            </p>
            <Button variant="red" onClick={props.onClick}>
              ENTENDIDO
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
