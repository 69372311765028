import { createSlice } from "@reduxjs/toolkit";
import cardicon2 from "../Assets/Images/chargers available-min.png";
import cardicon3 from "../Assets/Images/charger-min.png";
import cardimg4 from "../Assets/EV6 GT-Line (E-AWD)/CV_GT_Line_Front_Aurora_Black Pearl_ABP.png";
import cardicon1 from "../Assets/Images/time-min.png";
import Newcardimg3 from "../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Yacht_Blue_DU3.png";
import cardimg2 from "../Assets/EV6 EX RWD/CV_BL_Front_Snow_White_Pearl_SWP.png";

export const CarSlice = createSlice({
  name: "cars",
  initialState: [
    {
      id: 2,
      img: cardimg2,
      name: "EV6 EX RWD",
      finalPrice: "$54,595",
      price: "$54,595",
      discountprice: "$48,595*",
      // discountprice: "$49,595*",
      vorte: "697V",
      km: "77.4 kWh",
      rwd: "RWD",
      icon: cardicon1,
      icon2: cardicon2,
      icon3: cardicon3,
      MPGe: 310,
      isOfferActivated: false,
    },
    {
      id: 3,
      img: Newcardimg3,
      name: "EV6 GT-LINE RWD",
      finalPrice: "$57,695",
      price: "$57,695",
      discountprice: "$51,695*",
      // discountprice: "$52,695*",
      vorte: "697V",
      km: "77.4 kWh",
      rwd: "RWD",
      icon: cardicon1,
      icon2: cardicon2,
      icon3: cardicon3,
      MPGe: 310,
      isOfferActivated: false,
    },
    {
      id: 4,
      img: cardimg4,
      name: "EV6 GT-LINE (E-AWD)",
      finalPrice: "$62,795",
      price: "$62,795",
      discountprice: "$56,795*",
      // discountprice: "$57,795*",
      vorte: "697V",
      km: "77.4 kWh",
      rwd: "E-AWD",
      icon: cardicon1,
      icon2: cardicon2,
      icon3: cardicon3,
      MPGe: 260,
      isOfferActivated: false,
    },
  ],
  reducers: {
    ActivateOfferById(state, action) {
      const val = state.map((item) => {
        if (!item.isOfferActivated) {
          return {
            ...item,
            isOfferActivated: true,
            finalPrice: item.discountprice,
          };
        } else {
          return {
            ...item,
            isOfferActivated: false,
            finalPrice: item.price,
          };
        }
      });
      localStorage.setItem("offerdata", JSON.stringify(val));
      return val;
    },
    setOfferStatus(state, action) {
      const val = state.map((item) => {
        if (action.payload === "TRUE") {
          return {
            ...item,
            isOfferActivated: true,
            finalPrice: item.discountprice,
          };
        } else {
          return {
            ...item,
            isOfferActivated: false,
            finalPrice: item.price,
          };
        }
      });
      localStorage.setItem("offerdata", JSON.stringify(val));
      return val;
    },
    setFullData(state, action) {
      return action.payload;
    },
  },
});

export const { ActivateOfferById, setOfferStatus, setFullData } =
  CarSlice.actions;
