import { createContext, useState } from "react";

export const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
  const [D4000Show, setD4000Show] = useState(false);
  const [Gracias, setGraciasModal] = useState({ modal: false, pra: "" });
  const [Separatemodal, setseparatemodal] = useState(false);
  const [chatbotshow, setchatbotshow] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isshow, setishsow] = useState(false);
  const [modelsdropdown, setmodelsdropdown] = useState(false);
  const [Newev9, setnewev9] = useState(false);
  const [modelosoffset, setmodelosoffset] = useState(null);
  const [galleryModal, setgalleryModal] = useState(false);
  const [infoalertshow, setinfoslertshow] = useState(false);

  return (
    <GlobalContext.Provider
      value={{
        D4000Show,
        setD4000Show,
        modelosoffset,
        setmodelosoffset,
        Newev9,
        setnewev9,
        isshow,
        setishsow,
        modelsdropdown,
        setmodelsdropdown,
        Gracias,
        isExpanded,
        setIsExpanded,
        setGraciasModal,
        Separatemodal,
        setseparatemodal,
        chatbotshow,
        setchatbotshow,
        galleryModal,
        setgalleryModal,
        infoalertshow,
        setinfoslertshow,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
