import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import threesixty from "../../Assets/Images/360icon.png";
import tick from "../../Assets/Images/colortickicon.png";
import Button, { Bonobtn } from "../../Components/UI/Button";
import { CarData } from "../Data/Data";
import axios from "../../API";
import ReactGA from "react-ga";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import img1 from "../../Assets/EV6 GT-Line (E-AWD)/CV_GT_Line_Front_Yacht_Blue_DU3.png";
import img3 from "../../Assets/EV6 GT-Line (E-AWD)/CV_GT_Line_Front_Moonscape_Matte_KLM (1).png";
import img5 from "../../Assets/EV6 GT-Line (E-AWD)/CV_GT_Line_Front_Snow_White_Pearl_SWP.png";
import img7 from "../../Assets/EV6 GT-Line (E-AWD)/CV_GT_Line_Front_Runway_Red_CR5.png";
import img8 from "../../Assets/EV6 GT-Line (E-AWD)/CV_GT_Line_Front_Aurora_Black Pearl_ABP.png";
import Interior_2 from "../../Assets/Images/Interior_2.png";
import Aros1 from "../../Assets/Images/Aros_Black.png";
import { Modal } from "react-bootstrap";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { Store } from "../../Redux/MainStore";
import { ActivateOfferById } from "../../Redux/CarSlice";
import { handleOffer } from "../../Pages/Modelos/Modelos";
import { useContext } from "react";
import { GlobalContext } from "../../Context/GlobalContext";
import { Icons } from "../../Constant/Icons";
import { ShowAlertModal } from "../Modals/ShowAlertModal/ShowAlertModal";

const Model = () => {
  const [AlertModal, setAlertModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const { setishsow } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [active, setactive] = useState(0);
  const [color, setcolor] = useState(CarData[0]._CVGL1_CV4GL1_colors[2]);
  const [_Int_color] = useState(CarData[0]._CVGL1_CV4GL1_int_colors[0]);
  const [_Aros_color, set_Aros_color] = useState(
    CarData[0]._CVGL1_CV4GL1_aros_colors[0]
  );
  const [Loading, setLoading] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const UserData = JSON.parse(localStorage.getItem("user-info"));
  const ModalDataFromRedux = useSelector((state) => state.carData);
  const [HistoryData, setHistoryData] = useState({});
  const getOrderHistory = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/get-history", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${UserData.Token}`,
        },
      });
      if (res) {
        // console.log("OrderHistory...............", res);
        if (res.data.status == "success" && res.data.message == "Record") {
          setLoading(false);
          setHistoryData(res.data);
        } else {
          setLoading(false);
          // console.log("res Error....", res);
        }
      }
    } catch (error) {
      setLoading(false);
      // console.log("Catched Error....", error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("user-info")) {
      getOrderHistory();
    }
  }, []);

  function ModelModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        // backdrop="static"
        centered
        style={{ zIndex: "10000" }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="white text-center">
          <div className="Dropdown-Car-info-body-Head">
            <i>Información importante</i>
          </div>
          <p className="Modal-text">
            <div>
              Los precios que surgen en la página son válidos al momento en el
              que el Usuario reserva su vehículo mediante un depósito de
              Quinientos dólares, ($500.00). El precio de la reservación se
              honrará para el Usuario que reserve el vehículo mediante el pago
              referenciado. Sin embargo, los precios anunciados pueden cambiar
              en cualquier momento sin ningún tipo de aviso previo.
            </div>
          </p>
          <div className="p-1"></div>
        </Modal.Body>
      </Modal>
    );
  }

  function Orderplaced(props) {
    function ChangeBtn() {
      setModalShow(false);
      navigate("/Perfil/History");
    }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body className="white text-center pt-3">
          <h4 className="pb-3">Pedido ya realizado</h4>
          <p className="Modal-text">
            Usted ha realizado una reserva del EV por lo que con este usuario no
            puede volver a realizar una reserva.
          </p>
          <div className="p-2"></div>
          <div className="d-flex justify-content-around gap-3 cancle-modal-btns">
            <Button variant="red" onClick={ChangeBtn}>
              Ir al perfil
            </Button>
          </div>
          <div className="p-3"></div>
        </Modal.Body>
      </Modal>
    );
  }

  let CV4GL1_Price = 62795;

  let Ext_name;
  let Ext_code;
  let images;
  if (color == "#1A30AD") {
    Ext_name = "Yacht Blue";
    Ext_code = "#1A30AD";
    images = img1;
  } else if (color == "#933531") {
    Ext_name = "Runway Red";
    Ext_code = "#933531";
    images = img7;
  } else if (color == "#1B1B1B") {
    Ext_name = "Aurora Black Pearl";
    Ext_code = "#1B1B1B";
    images = img8;
  } else if (color == "#666666") {
    Ext_name = "Moonscape";
    Ext_code = "#666666";
    images = img3;
  } else if (color == "#C6C4B7") {
    Ext_name = "Snow White Pearl";
    Ext_code = "#C6C4B7";
    images = img5;
  }

  let Int_name;
  let Int_code;
  let Int_images;
  if (_Int_color == "BLACK & WHITE") {
    Int_name = "Interior blancos y negros";
    Int_code = "BLACK & WHITE";
    Int_images = Interior_2;
  }

  let Aros_images;
  if (_Aros_color == "#1B1B1B") {
    Aros_images = Aros1;
  }

  const StaticCartData = {
    ExtColorName: Ext_name,
    IntColorName: Int_name,
    ExtColorCode: Ext_code,
    IntColorCode: Int_code,
    Price: `${CV4GL1_Price}`,
    Model: "EV6 GT-LINE (E-AWD)",
    Aros_Type: "2",
    Mainmodel: "EV6",
    id: 4,
  };

  const StaticAddToCart = () => {
    setishsow(true);
    ReactGA.event({
      category: "Cart",
      action: "Add to Cart",
      label: StaticCartData.Model,
      price: StaticCartData.Price,
      Color: StaticCartData.ExtColorName,
    });
    if (HistoryData.status === "success" && HistoryData.message === "Record") {
      setModalShow2(true);
    } else if (
      HistoryData.status === "success" &&
      HistoryData.message === "No Record"
    ) {
      setLoading(true);
      if (localStorage.getItem("Register-Start")) {
        // if (UserData.Id) {
        setTimeout(() => {
          setLoading(false);
          localStorage.setItem(
            "StaticCartData",
            JSON.stringify(StaticCartData)
          );
          navigate("/Titular");
        }, 1000);
      } else {
        setTimeout(() => {
          setLoading(false);
          localStorage.setItem(
            "StaticCartData",
            JSON.stringify(StaticCartData)
          );
          navigate("/Cart");
        }, 1000);
      }
    } else {
      setLoading(true);
      if (localStorage.getItem("Register-Start")) {
        setTimeout(() => {
          setLoading(false);
          localStorage.setItem(
            "StaticCartData",
            JSON.stringify(StaticCartData)
          );
          navigate("/Titular");
        }, 1000);
      } else {
        setTimeout(() => {
          setLoading(false);
          localStorage.setItem(
            "StaticCartData",
            JSON.stringify(StaticCartData)
          );
          navigate("/Cart");
        }, 1000);
      }
    }
  };
  const { setD4000Show } = useContext(GlobalContext);

  return (
    <>
      <ModelModal show={modalShow} onHide={() => setModalShow(false)} />
      <ShowAlertModal show={AlertModal} onHide={() => setAlertModal(false)} />
      <Orderplaced show={modalShow2} onHide={() => setModalShow2(false)} />
      <form
      // onSubmit={handleSubmit}
      >
        {CarData.map((curelem, index) => {
          return (
            <div key={curelem.id} className="mainmodelcontent">
              <div className="modelpic">
                {active == 0 ? (
                  <div className="modelimgdiv">
                    <motion.img
                      initial={{ x: 70, opacity: 0 }}
                      whileInView={{ x: 0, opacity: 1 }}
                      transition={{ duration: 0.5 }}
                      src={images}
                      alt=""
                      className="modelimg"
                    />
                  </div>
                ) : active == 1 ? (
                  <img src={Int_images} alt="" className="interiorimg" />
                ) : active == 2 ? (
                  <img src={Aros_images} alt="" className="arosimg" />
                ) : null}
              </div>
              <div className="bhaismajhnhi">
                <div className="sixty">
                  <a
                    style={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://worldwide.kia.com/int/vr-showroom/csa/ev6/lhd/index.html#/ev6/exterior"
                  >
                    <img src={threesixty} alt="" />
                    ¡Conoce el EV6 en VR!
                  </a>
                </div>
                <div className="namemodel">
                  <div
                    className="namemodel"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      width: "auto",
                    }}
                  >
                    {ModalDataFromRedux.map((__item, index) => {
                      if (index === 2) {
                        return (
                          <Fragment key={__item.id}>
                            {__item.isOfferActivated && (
                              <span
                                className="line-through mainprice"
                                style={{
                                  fontSize: "30px",
                                  paddingRight: "20px",
                                }}
                              >
                                {__item.price}
                              </span>
                            )}
                            <h5
                              style={{
                                color: __item.isOfferActivated
                                  ? "#F3C300"
                                  : "#fff",
                              }}
                            >
                              {__item.isOfferActivated && (
                                <span
                                  className="discountamount"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setD4000Show(true);
                                  }}
                                >
                                  -$6,000*
                                </span>
                              )}

                              {/* <span>$</span> */}
                              {__item.finalPrice}
                              <span onClick={() => setModalShow(true)}>
                                {Icons.ico}
                              </span>
                            </h5>
                            <div className="buttonreserve">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "1rem",
                                  alignItems: "flex-start",
                                }}
                              >
                                <span className="carname">{__item.name}</span>
                                <div className="navinterior">
                                  <span
                                    onClick={() => setactive(0)}
                                    className={active === 0 ? "activespan" : ""}
                                  >
                                    Exterior
                                  </span>
                                  <div className="linediv4"></div>
                                  <span
                                    onClick={() => setactive(1)}
                                    className={active === 1 ? "activespan" : ""}
                                  >
                                    Interior
                                  </span>
                                  <div className="linediv4"></div>
                                  <span
                                    onClick={() => setactive(2)}
                                    className={active === 2 ? "activespan" : ""}
                                  >
                                    Aros
                                  </span>
                                </div>
                              </div>
                              {/* <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-end",
                                  gap: "10px",
                                }}
                              >
                                {__item.isOfferActivated ? (
                                  <Bonobtn
                                    onClick={(event) => {
                                      handleOffer(__item);
                                    }}
                                  >
                                    Bono Activado
                                  </Bonobtn>
                                ) : (
                                  <Button
                                    variant={"reserbtn2"}
                                    style={{ backgroundColor: "#878787" }}
                                    onClick={(event) => {
                                      handleOffer(__item);
                                    }}
                                  >
                                    Activar bono
                                  </Button>
                                )}
                              </div> */}
                            </div>
                          </Fragment>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
              <div className="colorsdiv">
                {active == 0 ? (
                  <>
                    <span>{Ext_name}</span>
                    <div>
                      {curelem._CVGL1_CV4GL1_colors.map((current, index) => {
                        return (
                          <button
                            onClick={() => setcolor(current)}
                            style={{ backgroundColor: current }}
                            key={index}
                            type="button"
                          >
                            {color === current ? (
                              <img src={tick} alt="" />
                            ) : null}
                          </button>
                        );
                      })}
                    </div>
                    <div
                      className="comparediv"
                      onClick={() => navigate("/Features")}
                    >
                      <span>{Icons.icooo}</span>
                      <span>Comparar con otros modelos</span>
                    </div>
                  </>
                ) : active == 1 ? (
                  <>
                    <span>{Int_name}</span>
                    {Icons.BlackWhite_Color}
                    {/* <div>
                    {curelem._CVGL1_CV4GL1_int_colors.map((current, index) => {
                      return (
                        <button
                          onClick={() => set_Int_color(current)}
                          style={{
                            backgroundColor:
                              "linear-gradient(to right, black 50%, white 100% )",
                          }}
                          key={index}
                          type="button"
                        >
                          {_Int_color === current ? (
                            <img src={tick} alt="" />
                          ) : null}
                        </button>
                      );
                    })}
                  </div> */}
                  </>
                ) : (
                  <>
                    <span>Gris oscuro 20”</span>
                    <div>
                      {curelem._CVGL1_CV4GL1_aros_colors.map(
                        (current, index) => {
                          return (
                            <button
                              onClick={() => set_Aros_color(current)}
                              style={{ backgroundColor: current }}
                              key={index}
                              type="button"
                            >
                              {_Aros_color === current ? (
                                <img src={tick} alt="" />
                              ) : null}
                            </button>
                          );
                        }
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="spicificamaindiv">
                <div className="spicificationdiv">
                  <h4>Especificaciones Mecánicas</h4>
                  <div>
                    <li>Motor Sincrónico de Imanes Permanentes</li>
                    <li>Tipo de tracción AWD</li>
                    <li>
                      Motor de tracción trasera de 239 kW con 320 caballos de
                      fuerza & 446 lbs- pies. de par de torsión
                    </li>
                    <li>
                      Freno de estacionamiento electrónico (EPB) con retención
                      automática
                    </li>
                    <li>
                      Sistema de frenado regenerativo con palancas de control
                    </li>
                    <li>Generador de energía a bordo (V2L)</li>
                  </div>
                </div>
                <div className="spicificationdiv">
                  <h4>Sistema Eléctrico</h4>
                  <div>
                    <li>Tipo de batería Iones de Litio</li>
                    <li>Voltaje (V) 697V</li>
                    <li>Capacidad de carga 77.4 kWh</li>
                    <li>Velocidad máxima (mph) (AWD) 115 mph</li>
                    <li>Sistema de carga múltiple (400V–800V) </li>
                  </div>
                </div>
                <div className="spicificationdiv">
                  <h4>Exterior</h4>
                  <div>
                    <li>
                      Aros de 20 pulgadas en ruedas aerodínamicas con acabado en
                      gris oscuro
                    </li>
                    <li>Estilo GT-Line en el interior y exterior</li>
                    <li>
                      Control automático de luces con asistencia de luces LED
                    </li>
                    <li>
                      Retrovisor plegable eléctrico con calefacción e
                      indicadores
                    </li>
                    <li>Cristal trasero con privacidad y spoiler trasero</li>
                    <li>Manijas automáticas</li>
                    <li>Puerta de baúl inteligente con apertura automática</li>
                    <li>Power Sunroof</li>
                    <li>Paquete de remolque</li>
                    <li>
                      Equipo de reparación para ruptura de goma (Tire Mobility
                      Kit) * la goma de repuesta no está incluida
                    </li>
                  </div>
                </div>
                <div className="spicificationdiv">
                  <h4>Interior</h4>
                  <div>
                    <li>Pantalla Panorámica Dual de 12.3" con navegación</li>
                    <li>Apple CarPlay® Android Auto™</li>
                    <li>Bluetooth® y Conectividad de manos libres</li>
                    <li>Llave inteligente con encendido por botón</li>
                    <li>Cargador Inalámbrico para teléfonos</li>
                    <li>Control automático de temperatura de zona dual</li>
                    <li>Asientos delanteros con calefacción</li>
                    <li>Asientos delanteros con ventilación</li>
                    <li>Asiento del conductor eléctrico</li>
                    <li>Materiales de asientos en cuero vegano</li>
                    <li>Paquete de asientos GT-Line Suede</li>
                    <li>Sistema de Audio Meridian Premium con 14 bocinas</li>
                    <li>Cuatro (4) puertos USB con carga rápida</li>
                    <li>Toma de corriente de 12V - parte delantera</li>
                    <li>Toma de corriente de 12V - parte trasera</li>
                    <li>Luces interiores de ambientación tipo LED</li>
                    <li>
                      Ventanas eléctricas con subida/bajada automática delantera
                    </li>
                    <li>
                      Consola central delantera fija con bandeja de
                      almacenamiento en apoyabrazos y portavasos doble
                    </li>
                  </div>
                </div>
                <div className="spicificationdiv">
                  <h4>Kia Drive Wise</h4>
                  <div>
                    <li>
                      Asistencia para prevención de colisión frontal (FCA) con
                      detección de peatones (sensor al girar)
                    </li>
                    <li>
                      Asistencia para prevención de colisión frontal (FCA) con
                      detección de peatones (sensor al girar y cruzar)
                    </li>
                    <li>
                      Asistencia para prevención de colisión en punto ciego
                      (BCA)
                    </li>
                    <li>
                      Asistencia para prevención de colisión de tráfico cruzado
                      posterior (RCCA)
                    </li>
                    <li>
                      Asistencia de retención de carril (LKA) y Asistencia de
                      seguimiento de carril (LFA)
                    </li>
                    <li>
                      Alerta de distancia de estacionamiento (PDW) Trasero
                    </li>
                    <li>Asistencia de salida segura (SEA)</li>
                    <li>
                      Control de crucero inteligente (SCC) con parada y arranque
                      + aprendizaje automático
                    </li>
                    <li>Alerta de ocupante trasero (ROA)</li>
                    <li>Sistema de monitoreo a vuelta redonda (SVM)</li>
                    <li>Monitor para punto ciego (BVM)</li>
                    <li>Asistencia para estacionamiento inteligente remoto</li>
                    <li>
                      Asistencia para prevención de colisión al estacionarse-
                      reversa
                    </li>
                    <li>Head-Up Display</li>
                  </div>
                </div>
                <div className="spicificationdiv">
                  <h4>Seguridad</h4>
                  <div>
                    <li>Sistema avanzado de bolsas de aire duales</li>
                    <li>Bolsa de aire para las rodillas del conductor</li>
                    <li>Bolsas de aire laterales en los asientos delanteros</li>
                    <li>Bolsas de aire laterales tipo cortina</li>
                    <li>
                      Cinturones de seguridad de 3 puntos para todas las
                      posiciones del asiento
                    </li>
                    <li>
                      Pretensores de cinturones de seguridad (asientos
                      delanteros)
                    </li>
                    <li>
                      Sistema de frenos antibloqueo (ABS) con frenos de disco en
                      las 4 ruedas
                    </li>
                    <li>Manejo de estabilidad vehicular (VSM)</li>
                    <li>Sistema de control de tracción</li>
                    <li>Control de estabilidad electrónica</li>
                    <li>Sistema de distribución electrónica de frenos (EBD)</li>
                    <li>Sistema de asistencia de frenado (BA)</li>
                    <li>Control de asistencia de en cuestas (HAC)</li>
                    <li>
                      Sistema de monitoreo para la presión de aire en las gomas
                      (TPMS)
                    </li>
                    <li>con indicador de presión individual</li>
                    <li>
                      Sistema de anclaje para asiento protector de niños (LATCH)
                    </li>
                    <li>
                      Cerraduras de seguridad para niños en las puertas traseras
                    </li>
                    <li>Apertura de emergencia interna del baúl</li>
                  </div>
                </div>
                <div className="spicificationdiv">
                  <h4>Medidas/ capacidades exteriores</h4>
                  <div>
                    <li>Largo 184.8 in.</li>
                    <li>Ancho (sin espejos laterales) 74.4 in</li>
                    <li>Altura 60.8 in</li>
                    <li>Distancia entre ejes 114.2 in</li>
                    <li>Alero (delantero/trasero) 33.3 in. / 37.4 in.</li>
                    <li>Track (delantero/trasero) 64.2 in. / 64.6 in.</li>
                    <li>Claridad del piso 6.1 in.</li>
                  </div>
                </div>
                <div className="spicificationdiv">
                  <h4>Medidas/ capacidades interiores</h4>
                  <div>
                    <li>
                      Espacio para la cabeza con sunroof (delantero/trasero)
                      36.8/38.0 in.
                    </li>
                    <li>
                      Espacio para las piernas (delantero/trasero) 42.4/39.0 in
                    </li>
                    <li>
                      Espacio para los hombros (delantero/trasero) 57.8/55.6 in.
                    </li>
                    <li>
                      Espacio para las caderas (delantero/trasero) 54.9/53.2 in
                    </li>
                    <li>Volumen de pasajeros 103.0 cu. ft.</li>
                    <li>
                      Volumen de carga (asientos traseros levantados/asientos
                      traseros recogidos) 24.4 / 50.2 cu. ft.
                    </li>
                  </div>
                </div>
                {/* <div className="spicificationdiv">
                  <h4>Peso</h4>
                  <div>
                    <li>Peso en vacío 4,255 lbs.</li>
                    <li>GVWR 5,335 lbs.</li>
                    <li>Capacidad de remolque con freno 2,300 lbs.</li>
                  </div>
                </div> */}
                <div className="spicificationdiv">
                  <h4>MPGe</h4>
                  <div>
                    <li>Ciudad 117</li>
                    <li>Autopista 96</li>
                    <li>Combinado 109</li>
                    <li>Autonomía totalmente eléctrica (millas) 260</li>
                  </div>
                </div>
              </div>
              <div className="reservebtn">
                {/* <button type="submit">Reservar</button> */}
                {/* <button type="button" onClick={() => setAlertModal(true)}> */}
                {/* <button type="button" onClick={() => StaticAddToCart()}>
                  Reservar
                </button> */}
              </div>
            </div>
          );
        })}
        {!Loading && <div></div>}
        {Loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </form>
    </>
  );
};

export default Model;
